<template>
<div>
    <v-container fluid class="grid-list-xl pb-0 mt-12 mx-0 px-0">
        <v-row>
            <v-col cols="12" class="text-center lightred--text">
                <h2 class="font-2x">ioBots Market Types</h2>
            </v-col>
        </v-row>
        <!-- 加密货币 -->
        <v-row class="mx-1 mb-5 mt-16 text-center">
            <v-col cols="12" class="pa-0">
                <span class="lightred--text fw-bold fs-20">{{ $t('marketTypes.cryptoMarkets') }}</span>
            </v-col>
        </v-row>
        <v-row class="mx-1 mb-5 mt-5">
            <v-col cols="12" md="4" xs="4" v-if="marketTypeCryptoCurrencies == null" class="pa-0 mb-15" v-for="i in 12">
                <v-skeleton-loader ref="skeleton" type="heading" tile class="ml-2 mb-3"></v-skeleton-loader>
                <v-skeleton-loader ref="skeleton" type="image@2" tile :height="340" class="ml-2 mr-5"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="4" xs="4" class="pa-0" v-for="(currency, index) in marketTypeCryptoCurrencies" :key="index">
                <!-- id加上index是为了不和图表的id重复 -->
                <!-- border-shortdash -->
                <div class="" :id="index + '-' + currency">
                    <market-type-crypto-chart class="my-4" :currency="currency"></market-type-crypto-chart>
                </div>
            </v-col>
        </v-row>
        <!-- <v-col cols="12" md="4" xs="4" class="pa-0">
                <div class="border-shortdash" id="BTC1">
                    <market-type-chart class="my-4" currency="BTC"></market-type-chart>
                </div>
            </v-col> -->
        <!-- 股票市场 -->
        <v-row class="mx-1 mb-5 mt-16 text-center">
            <v-col cols="12" class="pa-0">
                <span class="lightred--text fw-bold fs-20">{{ $t('marketTypes.equityMarkets') }}</span>
            </v-col>
        </v-row>
        <v-row class="mx-1 mb-5 mt-5">
            <v-col cols="12" md="4" xs="4" class="pa-0" v-for="(currency, index) in equityMarketsCurrencies" :key="index">
                <!-- id加上index是为了不和图表的id重复 -->
                <div class="" :id="index + '-' + currency">
                    <market-type-traditional-chart class="my-4" :currency="currency" :id="currency"></market-type-traditional-chart>
                </div>
            </v-col>
            <v-col cols="12" md="4" xs="4" class="pa-0" v-for="(currency, index) in newEquityMarketsCurrencies" :key="currency + index">
                <!-- id加上index是为了不和图表的id重复 -->
                <div class="" :id="index + '-' + currency">
                    <market-type-synthetic-chart class="my-4" :currency="currency" :id="currency"></market-type-synthetic-chart>
                </div>
            </v-col>
        </v-row>
        <!-- 商品市场 -->
        <v-row class="mx-1 mb-5 mt-16 text-center">
            <v-col cols="12" class="pa-0">
                <span class="lightred--text fw-bold fs-20">{{ $t('marketTypes.commoditiesMarkets') }}</span>
            </v-col>
        </v-row>
        <v-row class="mx-1 mb-5 mt-5">
            <v-col cols="12" md="4" xs="4" class="pa-0" v-for="(currency, index) in commoditiesMarketsCurrencies" :key="index">
                <!-- id加上index是为了不和图表的id重复 -->
                <div class="" :id="index + '-' + currency">
                    <market-type-traditional-chart class="my-4" :currency="currency" :id="currency"></market-type-traditional-chart>
                </div>
            </v-col>
            <v-col cols="12" md="4" xs="4" class="pa-0" v-for="(currency, index) in newCommoditiesMarketsCurrencies" :key="currency+ index">
                <!-- id加上index是为了不和图表的id重复 -->
                <div class="" :id="index + '-' + currency">
                    <market-type-synthetic-chart class="my-4" :currency="currency" :id="currency"></market-type-synthetic-chart>
                </div>
            </v-col>
        </v-row>
    </v-container>
    <app-footer></app-footer>
    <mobile-btn></mobile-btn>
</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import MarketTypeCryptoChart from '@/highcharts/MarketTypeCryptoChart';
    import MarketTypeTraditionalChart from '@/highcharts/MarketTypeTraditionalChart';
    import MarketTypeSyntheticChart from '@/highcharts/MarketTypeSyntheticChart';
    import MobileBtn from "@/components/common/MobileBtn";

    export default {
        data(){
            return {
                // 股票市场
                // 20221216：将 'SPX', 'China A50', 'TSLA', 'MSFT' 从 equityMarketsCurrencies -> newEquityMarketsCurrencies，统一数据源
                equityMarketsCurrencies: [],
                newEquityMarketsCurrencies: ['SPX', 'China A50', 'TSLA', 'MSFT', 'Google', 'Amazon', 'Apple'],
                // 商品市场
                // 20221216：将 'GOLD' 从 commoditiesMarketsCurrencies -> newCommoditiesMarketsCurrencies，统一数据源
                commoditiesMarketsCurrencies: [],
                newCommoditiesMarketsCurrencies: ['GOLD', 'Silver', 'Crude Oil']
            }
        },
        components: { MarketTypeCryptoChart, MarketTypeTraditionalChart, MarketTypeSyntheticChart,MobileBtn },
        created(){
            this.$store.dispatch("indicatorsHeaderTitleHandler", 'marketTypes');
            this.$store.dispatch("mobileBtnPageHandler",1);
        },
        mounted(){

        },
        computed: {
            ...mapGetters(['responsive', 'marketTypeCryptoCurrencies']),
        },
        watch:{

        },
        methods: {

        },
    }
</script>
